

































































































































































































.buy-tickets
  border-radius 5px
  border none
  padding 5px
  width 100%
  height 50px
  text-transform uppercase
  font-weight 700
  &.disabled
    opacity 0.5

.need-help
  margin 20px 0 10px
  font-size 90%
  a
    color convert(brandColor)
    font-weight 500
    text-decoration underline
